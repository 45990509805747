import React, { useState, useRef } from 'react'
import ReCAPTCHA from "react-google-recaptcha"
import axios from 'axios'

const Email = ( { show, handleClose } ) => {
    const [ formData, setFormData ] = useState(
        {
            "reason": "job",
            "name": "",
            "email": "",
            "message": "",
            "captcha": ""
        }
    )
    
    const captchaRef = useRef(null)

    function handleChange(event) {
        console.log(event)
        const { name, value, type, checked } = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    } 

    const divStatus = show ? "modal display-block flex flex-col bg-white py-10" : "modal display-none flex flex-col bg-white py-10"

    const btnStatus = ( btnName )=> {
        const btnActive = "bg-white p-2 shadow-md rounded-lg text-base font-bold hover:font-bold text-left border-fuchsia-400 border-2"
        const btnInactive = "bg-white p-2 shadow-md rounded-lg text-base font-medium hover:font-bold text-left border-slate-400 border"
        
        switch(btnName) {
            case(formData.reason): return btnActive
            default: return btnInactive
        }
    }

    const sendButtonStatus = (captcha) => {
        const sendButtonActive = "text-white bg-fuchsia-700 hover:bg-fuchsia-800 focus:ring-4 focus:outline-none focus:ring-fuchsia-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center dark:bg-fuchsia-600 dark:hover:bg-fuchsia-700 dark:focus:ring-fuchsia-800"
        const sendButtonInactive = "text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-4 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"

        const captchaLen = (captcha || []).length

        switch(true){ 
        case captchaLen === 0:
             return sendButtonInactive
        default:
             return sendButtonActive
        }
    }

    const handleSubmit = async (e) =>{
        e.preventDefault();

        // const token = captchaRef.current.getValue();
        // captchaRef.current.reset();

        await axios.post(process.env.GATSBY_EMAIL_API, {formData})
        .then(res =>  console.log(res))
        .catch((error) => {
        console.log(error);
        })
    }

    return (
        <div className={ divStatus } onClick={ handleClose }>
            <div className='container mx-auto'>
                <div className='mx-auto flex flex-col justify-center max-w-full 
                    lg:max-w-[510px] rounded-lg border-2 border-slate-400 
                    shadow-xl bg-gray-100 p-4'
                    onClick={e => e.stopPropagation()}
                >
                    <div className='flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600'>
                        <div className='pb-1 text-xl'>You are inquiring about</div>
                        <button className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                            onClick={ handleClose }
                        >
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>    
                    <div className='grid grid-cols-3 grid-row-1 gap-2 pb-3'>
                        <div className={btnStatus("job")}
                            name="reason"
                            value="job"
                            onClick={() => setFormData(prevState => {return {...prevState, reason: "job"}})}
                            >
                            <div className='flex flex-col'>
                                <div className='pb-1'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" 
                                        fill="none" viewBox="0 0 24 24" 
                                        stroke="currentColor" 
                                        strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                </div>
                                <span>Job</span>
                            </div>
                        </div>
                        <button className={btnStatus("consulting")}
                            name="reason"
                            onClick={() => setFormData(prevState => {return {...prevState, reason: "consulting"}})}
                            >
                            <div className='pb-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" 
                                    fill="none" viewBox="0 0 24 24" 
                                    stroke="currentColor" 
                                    strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                                </svg>
                            </div>
                            <span>Consulting</span>
                        </button>
                        <button className={btnStatus("other")}
                            name="reason"
                            onClick={() => setFormData(prevState => {return {...prevState, reason: "other"}})}
                            >
                            <div className='pb-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" 
                                    fill="none" viewBox="0 0 24 24" 
                                    stroke="currentColor" 
                                    strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                </svg>
                            </div>
                            <span>Other</span>
                        </button>
                    </div>
                    <div className=''>
                        <form>
                            <div className='grid grid-cols-1 grid-row-2'>
                                <input
                                    id="name"
                                    name="name"
                                    required
                                    pattern=".*\S.*"
                                    maxLength={128}
                                    type="text"
                                    onChange={handleChange}
                                    className='bg-white p-2 shadow-md rounded-lg text-base 
                                        font-medium text-left 
                                        border-slate-400 border 
                                        focus:ring-1 focus:ring-fuchsia-400
                                        focus:border-fuchsia-400 focus:outline-none'
                                />
                                <label className='text-xl font-medium' htmlFor="contactName">
                                    Name
                                </label>
                            </div>
                            <div className='grid grid-cols-1 grid-row-2'>
                                <input
                                    id="email"
                                    name="email"
                                    required
                                    pattern=".*\S.*"
                                    maxLength={128}
                                    type="text"
                                    onChange={handleChange}
                                    className='bg-white p-2 shadow-md rounded-lg text-base 
                                        font-medium text-left 
                                        border-slate-400 border 
                                        focus:ring-1 focus:ring-fuchsia-400
                                        focus:border-fuchsia-400 focus:outline-none'
                                />
                                <label className='text-xl font-medium' htmlFor="email">
                                    Email
                                </label>
                            </div>
                            <div className='grid grid-cols-1 grid-row-2'>
                                <textarea
                                    id="message"
                                    name="message"
                                    required
                                    rows={8}
                                    pattern=".*\S.*"
                                    maxLength={500}
                                    type="text"
                                    onChange={handleChange}
                                    className='bg-white p-2 shadow-md rounded-lg text-base 
                                        font-medium text-left 
                                        border-slate-400 border 
                                        focus:ring-1 focus:ring-fuchsia-400
                                        focus:border-fuchsia-400 focus:outline-none'
                                />
                                <label className='text-xl font-medium' htmlFor="message">
                                    { formData["reason"] === "job" ? 'Tell me about your project' : 'Tell me how can I help' }
                                </label>
                            </div>
                            <div className='flex justify-center p-4'>
                                <ReCAPTCHA 
                                    sitekey={process.env.GATSBY_RECAPTCH2_APP_SECRET_KEY}
                                    ref={captchaRef}
                                    name="captcha"
                                    onChange={(event) => setFormData(prevState => {return {...prevState, captcha: event}})}
                                />
                            </div>
                            <div className='flex justify-center p-2'>
                                <button disabled={(formData.captcha || []).length === 0}
                                    className={sendButtonStatus(formData.captcha)}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Email