import React, { useContext, useRef } from 'react'
import { ScrollContext } from '../utils/scroll-observer'

const opacityForBlock = (sectionProgress, blockNum) => {
    const progress = sectionProgress - blockNum
    if (progress >=0 && progress <1) return 1
    return 0.2
}

const Principles = () => {
    const { scrollY } = useContext(ScrollContext)
    const refContainer = useRef()

    const numOfPages = 5
    let progress = 0

    const { current: elContainer } = refContainer
    if (elContainer) {
        const { clientHeight, offsetTop } = elContainer
        const screenH = window.innerHeight
        const halfH = screenH / 2
        const percentY = Math.min(clientHeight + halfH, 
            Math.max(-screenH, scrollY - offsetTop) + halfH) / clientHeight
        progress = Math.min(numOfPages - 0.5, Math.max(0.5, percentY * numOfPages))
    }
    return (
        <div ref={refContainer} className='bg-black text-white'>
            <div className='min-h-screen max-w-5xl mx-auto px-10 lg:px-20 py-24 md:py-28 lg:py-36 flex flex-col justify-center items-center text-4xl md:text-5xl lg:text-6xl tracking-tight font-semibold'>
                <div className='leading-[1.15]'>
                    <div className='bulletText' style={{
                        opacity: opacityForBlock(progress, 0)
                    }}>In my work I follow these principles:</div>
                    <span className={`bulletText inline-block after:content-['_']`} style={{
                        opacity: opacityForBlock(progress, 1)
                    }}>During planning I prefer going beyond the technical app architecture and understand the consumer need that we are trying to solve.</span>
                    <span className='bulletText inline-block' style={{
                        opacity: opacityForBlock(progress, 2)
                    }}>Establishing a good tests coverage is critical from the very beginning, if not going with a test driven approach (TDD).</span>
                    <span className='bulletText inline-block' style={{
                        opacity: opacityForBlock(progress, 3)
                    }}>Once finished with the core functionality, resource consumption analysis (cpu, memory load etc.) is the key to a high-performing application. </span>
                    <span className='bulletText' style={{
                        opacity: opacityForBlock(progress, 4)
                    }}>Keep learning and levelling up.</span>
                </div>
            </div>
            
        </div>
    )
}

export default Principles