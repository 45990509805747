import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"

import { Tile, TileBackground, TileContent, TileWrapper } from './tile'
import { ComptBackground, ComptContainer, ComptLeft, ComptRight } from './tile-text'


const Competencies = () => {
    const data = useStaticQuery(
        graphql`
      query {
        pcimg: file(relativePath: { eq: "Laptop-Mockup-02-Silver.png" }) {
            childImageSharp {
              fluid(maxWidth: 840) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        hobby: file(relativePath: { eq: "evantravelers-com-corneish-zen2-opt.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 840) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
    )

    return (
    <TileWrapper numOfPages={2}>
        <TileBackground>
            <ComptBackground />
        </TileBackground>
        <TileContent>
            <Tile page={0} renderContent={({ progress }) => (
                <ComptContainer>
                    <ComptLeft progress={progress}>
                        <div>
                            <div>Skills & Expertise</div>
                            <div className='text-xl md:text-xl tracking-tight'>
                                I prefer writing back-end services in Go, but 
                                can write solutions in Python, JS and TS. I work 
                                with both SQL and noSQL databases depending on 
                                the task, containerized, serverless apps and 
                                container orchestration systems, with different 
                                cloud providers like AWS and Azure.
                                I mostly do APIs, but I know how to create SPAs with 
                                SSG and SSRs.
                            </div>
                        </div>
                    </ComptLeft>
                    <ComptRight progress={progress}>
                        <Img fluid={data.pcimg.childImageSharp.fluid} 
                            alt="Tech logos on a laptop screen" 
                        />
                    </ComptRight>
                </ComptContainer>
            )}
            ></Tile>
        </TileContent>
        <TileContent>
            <Tile page={1} renderContent={({ progress }) => (
                <ComptContainer>
                    <ComptLeft progress={progress}>
                    <div>
                        <div>Hobbies</div>
                        <div className='text-xl md:text-xl tracking-tight'>
                            In my free time I like engeneering PCBs for custom 
                            keyboads and home automation projects as well as 
                            code them in the end.
                        </div>
                    </div>
                    </ComptLeft>
                    <ComptRight progress={progress}>
                        <Img fluid={data.hobby.childImageSharp.fluid} 
                            alt="Hobby" 
                        />
                    </ComptRight>
                </ComptContainer>
            )}
            ></Tile>
        </TileContent>
    </TileWrapper>
    )
}

export default Competencies