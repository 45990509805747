import React, { useRef, useContext, useState, useCallback } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from "gatsby-plugin-image"

import { BgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import { ScrollContext } from '../utils/scroll-observer'

const Masthead = () => {
    const { backgroundImage09 } = useStaticQuery(
        graphql`
            query {
                backgroundImage09: file(relativePath: {eq: "09.png"}) {
                childImageSharp {
                    gatsbyImageData(width: 1920, webpOptions: {quality: 90}, quality: 90)
                }
                }
            }
        `
    )
    const pluginImg = getImage(backgroundImage09)

    const [imageLoaded, setImageLoaded] = useState(false)

    const refContainer = useRef()
    const { scrollY } = useContext(ScrollContext)

    let progress = 0

    const { current: elContainer } = refContainer
    if (elContainer) {
        progress = Math.min(1, scrollY / elContainer.clientHeight)
    }

    const handleImageLoaded = useCallback(() => {
        setImageLoaded(true)
    }, [])

    return (
        <div 
            className='sticky top-0 -z-10'
            ref={refContainer}
            style={{
                transform: `translateY(-${progress * 20}vh)`
            }}
        >
        <BgImage 
            image={pluginImg} 
            className='absolute w-full h-full object-cover bg-gradient-to-r from-pink-500 via-red-500 to-fuchsia-500 background-animate'
            onLoad={handleImageLoaded}
        >
            <div className='min-h-screen flex flex-col items-center justify-center'>
                <div className='p-12 font-bold bg-clip-text text-white drop-shadow-[0_5px_3px_rgba(0,0,0,0.4)] text-center flex-1 flex items-center justify-center flex-col'>
                    <h1 className='mb-6 text-4xl xl:text-5xl'>Alex Kryuk</h1>
                    <h2 className='mb-2 text-2xl xl:text-3xl tracking-tight'>
                        <span>Connecting dots in modern cloud-native applications</span>
                    </h2>
                </div>   
                <div className={`flex-grow-0 pb-20 md:pb-10 transition-all duration-1000 ${
                    imageLoaded ? 'opacity-100' : 'opacity-0 -translate-y-10'
                }`}>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        className="h-12 w-12" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="white" 
                        strokeWidth={2}
                        alt='scroll down'
                    >
                    <path strokeLinecap="round" 
                        strokeLinejoin="round" 
                        d="M19 9l-7 7-7-7" 
                    />
                    </svg>
                </div>
            </div>
        </BgImage>
        </div>
    )
}

export default Masthead