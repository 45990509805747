import React, { useRef, useContext, useState, useCallback } from 'react'

const AboutMe: React.FC = () => {
    return (
        <section className='flex flex-col bg-white py-20 text-2xl md:text-3xl'>
            <div className='container mx-auto px-11'>
                <p className='leading-tight max-w-5xl mx-auto text-2xl lg:text-3xl tracking-tight pb-4'>
                    <strong>An experienced backend engineer with a full-stack 
                        development skills.</strong> 
                </p>
                <p className='leading-tight max-w-5xl mx-auto text-2xl lg:text-3xl tracking-tight'>        
                        As an IT 
                        infrastructure professional with many years of experience, 
                        I use my passion and skills while helping various industries 
                        and clients on 
                        solving their operational and growth challenges. I mostly 
                        specialize in the <span className='text-fuchsia-500'>cloud-native back-end developments, data 
                        pipelines and transformations</span>, but I have experience 
                        developing <span className='text-fuchsia-500'>full-stack applications</span> as well. 
                        I am based in Ontario, Canada.
                </p>
            </div>
        </section>
    )
}

export default AboutMe